import { render, staticRenderFns } from "./downsetting.vue?vue&type=template&id=00100ec8&scoped=true&"
import script from "./downsetting.vue?vue&type=script&lang=js&"
export * from "./downsetting.vue?vue&type=script&lang=js&"
import style0 from "./downsetting.vue?vue&type=style&index=0&id=00100ec8&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00100ec8",
  null
  
)

export default component.exports