<template>
  <div class="downSetting flex">
    <li class="flex overflow flexCenter">
      <div class="downLi overflow">
        <p class="flex downTitle flexCenter"><img src="../assets/action.png" /><span>群管理</span><img
            src="../assets/ac_right.png" /></p>
        <div class="downContent overflow">
          <p class="baseFour">文件管理</p>
          <input type="text" class="downInput" v-model="info.save_address">
          <p class="downAddress baseFour">图片文件的默认保存位置</p>
          <div class="downBtnGroup flex flexCenter">
            <p class="yellowBg textCenter baseFour overflow cursor">更改
              <label  class="changePath">
              <input type="file"  title=""  style="display:none;" class="changePath" id="choose" @change="getDic" nwdirectory >
            </label>
            </p>
            <!-- @click="setAddress" -->
            <p class="yellowBg textCenter baseFour cursor" @click="openDefault">打开文件夹</p>
          </div>
        </div>
      </div>
    </li>
    <!-- <li class="flex overflow flexCenter">
    <div class="downLi overflow">
          <p class="flex downTitle flexCenter"><img src="../assets/action.png"/><span>企业群管理</span><img src="../assets/ac_right.png"/></p>
         <div class="downContent overflow">
      <p class="baseFour">企业群管理</p>
     <input type="text" class="downInput">
     <p class="downAddress baseFour">图片文件的默认保存位置</p>
     <div class="downBtnGroup flex flexCenter">
        <p class="yellowBg textCenter baseFour">更改</p>
        <p class="yellowBg textCenter baseFour">打开文件夹</p>
     </div>
    </div>
  </div>
    </li> -->
  </div>
</template>
  <script>
import api from '../../http/api';
import { mapState } from 'vuex';
import { Message } from 'element-ui';
import { openPath } from '../../utils/win';
import { getInfo } from "../../utils";
export default {
  data() {
    return {
      // choosePath:'',//下载文件路径
    }
  },
  computed: {
    ...mapState(['info']),
  },
 async created() {
    let that = this;
  },
  activated() {
       !this.info.save_address&&getInfo()
  },
  methods: {
    //选择路径
    getDic() {
      let file = document.getElementById("choose");
      console.log('路径', file.files[0].path);
       this.$store.commit('addInfo',{save_address:file.files[0].path})
      this.setAddress()
    },
   //打开默认文件夹
   openDefault(){
    top.window.require&&openPath(this.info.save_address)
   },
    //设置下载地址
    setAddress() {
      let that = this;
      if (!that.info.save_address) {
        Message({
          message: `请设置图片文件的默认保存位置~`,
          type: 'error'
        });
        return;
      }
      localStorage.setItem('downAdress',that.info.save_address)
      api.settingAddress({
        save_address: that.info.save_address,
      }).then(res => {
        //console.log('设置res', res)
       Message({
          message:res.code==200? '设置成功~':'设置失败~',
          type: res.code==200?'success':'error'
        });
      })
    }
  }
}
</script>
  <style lang="less" scoped>
  .downSetting {
    height: 100%;
    width: 100%;
  
    li {
      margin: 0 5px;
      background: #F5F5F5;
      height: 100%;
      width: 100%;
  
      .downLi {
        width: 350px;
  
        .downTitle {
          img {
            width: 25px;
  
          }
  
          span {
            font-size: 22px;
            margin: 0 15px;
          }
        }
  
        .downContent {
          width: 100%;
          margin-top: 54px;
  
          .downInput {
            width: 100%;
            border: 1px solid #DCDCDC;
            line-height: 48px;
            box-sizing: border-box;
            margin: 8px 0 8px 0;
            padding: 0px 5px;
          }
  
          .downBtnGroup {
            margin-top: 35px;
  
            p {
              line-height: 35px;
              width: 109px;
              margin-right: 20px;
            }
          }
  
          .downAddress {
  
            color: #A5A5A5;
          }
        }
      }
    }
  
    li:first-child {
      margin-left: 0;
    }
  
    li:last-child {
      margin-right: 0;
    }
  }
  
  .changePath {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 10;
    top: 0;
    left: 0;
  
  }
  </style>
